<template>
  <div class="app">
    
    <!-- <van-nav-bar title="维保单位查询" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="ellipsis" size="20" color="#000" />
      </template>
    </van-nav-bar> -->
    
    <div class="search">
      <van-search
        v-model="value"
        show-action
        clearable
        shape="round"
        placeholder="维保单位名称"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
    </div>

    <van-pull-refresh v-model="refreshing" :head-height="50" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="到底啦~" error-text="请求失败，点击重新加载"
          @load="onLoad">
          <van-cell v-for="item in companyData" :key="item.id">
            <!-- <div class="min"> -->
            <div 
              class="info" 
              @click="infoSubmit(item)">
              <h5>{{item.name}}</h5>
              <div class="info-outside">
                <div class="info-item">
                  <img 
                    src="../../assets/info1.png" 
                    alt="">
                  <span>维保电梯数</span>
                  <div class="nav">
                    <span>{{item.elevatorCount}}</span>部
                  </div>
                </div>
                <div class="info-item">
                  <img 
                    src="../../assets/info2.png" 
                    alt="">
                  <span>从业人员</span>
                  <div class="nav">
                    <span>{{item.userCount}}</span>人
                  </div>
                </div>
              </div>
              <div class="info-outside">
                <div class="info-item">
                  <img 
                    src="../../assets/serial.png" 
                    alt="">
                  <span>备案日期</span>
                  <p>{{item.recordTime | formatDate()}}</p>
                </div>
              </div>
            </div>
          <!-- </div> -->
          </van-cell>
      </van-list>   
    </van-pull-refresh>

    <!-- <div class="min">
      <div class="info" @click="infoSubmit">
        <h5>南宁广安电梯工程有限公司</h5>
        <div class="info-outside">
          <div class="info-item">
            <img src="../../assets/info1.png" alt="">
            <span>维保电梯数</span>
            <div class="nav">
              <span>210</span>部
            </div>
          </div>
          <div class="info-item">
            <img src="../../assets/info2.png" alt="">
            <span>从业人员</span>
            <div class="nav">
              <span>210</span>人
            </div>
          </div>
        </div>
        <div class="info-outside">
          <div class="info-item">
            <img src="../../assets/serial.png" alt="">
            <span>备案日期</span>
            <p>2020-02-20</p>
          </div>
        </div>
      </div>
    </div> -->


  </div>
</template>

<script>
import { Toast } from 'vant'
import {getCompanyList} from '../../api/public'

export default {
   data () {
    return {
      value: '',
      loading: false,
      refreshing: false,
			finished: false,
      value: '',
      form: {
					limit: 10,
					page: 1
			},
			companyData:[]
    }
  },
  created() {
    this.onLoad();
  },
  methods: {
    onSearch () {
      this.onRefresh();
    },
    onCancel () {
      Toast('取消')
    },
    onClickLeft () {
      this.$router.push('home')
    },
    infoSubmit (item) {
      this.$router.push({path: 'detailsWbdw', query:{id: item.id}})
    },
    async onRefresh() {
		this.form.page = 1;
		this.companyData = [];
		this.finished = false;
		//this.loading = true;
		this.onLoad();
	},
	async onLoad() {

	   this.loading = true;
	   this.form.name = this.value || null;
	   console.info(this.form)

		getCompanyList(this.form).then(response=>{
			if(this.refreshing){
				setTimeout(() => { 
				   this.refreshing = false;
			    }, 1000);
				//this.loading = false;
			}

			this.companyData = this.companyData.concat(response.data);
			if (response.data.length < this.form.limit) {
				this.finished = true;
			} else {
				this.form.page++;
				this.finished = false;
			}
			setTimeout(() => { 
				this.loading = false;
			}, 2000);
			 
			}).catch(error=>{
				this.finished = true;
				this.loading = false;
			})
		},
      // async onRefresh() {
			// 	try {
			// 		this.form.page = 1;
			// 		this.companyData = [];
      //     this.finished = false;
			// 		this.onLoad();
			// 		this.refreshing = false;
			// 	} catch (error) {
			// 		this.refreshing = false;
      //     this.finished = true;
			// 	}
			// },
			// async onLoad() {
			// 	this.getCompanyList();
			// },
			// async getCompanyList(){
			// 	try {
			// 		this.loading = true;
			// 		this.form.name = this.value || null;
			// 		const response = await getCompanyList(this.form);
			// 		this.loading = false;
			// 		if(response.resultCode == 200){
			// 		  this.companyData = this.companyData.concat(response.data);
			// 		  if (response.data.length < this.form.limit) {
			// 				this.finished = true;
			// 			} else {
			// 				++this.form.page
			// 			}
			// 		}else{
      //       this.finished = true;
      //     }
			// 	} catch (error) {
			// 		this.loading = false;
      //     this.finished=true;
			// 	}
			// },
  }
}
</script>

<style scoped>
.search .van-search__action {
  color: #3369FD;
}
.search /deep/ .van-icon-scan {
  margin-right: 5px;
}
.min {
  /* padding-top: 15px; */
}
.info {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
}
.min .info h5 {
  font-size: 16px;
  font-weight: 500;
  color: #383A3D;
}
.info .info-outside {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}
.info .info-item {
  width: 315px;
}
.info .info-item img {
  width: 13px;
  height: 13px;
  position: relative;
  top: 2px;
}
.info .info-item span {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  margin-left: 10px;
}
.info .info-item p {
  margin: 6px 0 0 23px;
  font-size: 13px;
  font-weight: 400;
  color: #383A3D;
}
.info .info-item .nav {
  margin: 6px 0 0 14px;
  font-size: 13px;
  font-weight: 400;
  color: #383A3D;
}
.info-item .nav span {
  color: #383A3D;
}
</style>
